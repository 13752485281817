









































































import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/module'

import InfoBlock from './components/InfoBlock.vue'
import Banner from './components/Banner.vue'
import ContactBlock from '@/components/ContactBlock.vue'
import HelpBlock from '@/components/HelpBlock.vue'
import FooterBlock from '@/components/FooterBlock.vue'
import AppDownload from './components/AppDownload.vue'

@Component({
  components: {
    InfoBlock,
    Banner,
    ContactBlock,
    HelpBlock,
    FooterBlock,
    AppDownload
  },
})
export default class Member extends Vue {

  get bannerData() {
    return {
      imgH5: require('@/assets/queue/banner-h5.png'),
      imgPc: require('@/assets/queue/banner-pc.png'),
      alt: '「等味」餐飲智能排隊 讓等味更方便·省時'
    }
  }

  get isH5() {
    return AppModule.isH5
  }

  get images() {
    return [
      'https://spics.inctrip.com/6e612827fede85d6ce48187dc71e1e78.png',
      'https://spics.inctrip.com/8bdbef5c193b550c9ef0b28299586601.png',
      'https://spics.inctrip.com/476d69a3b55e700661b623531dbe347e.png',
      'https://spics.inctrip.com/84626cf791f99cb398e697b1d7c6f5d5.png'
    ]
  }

}
